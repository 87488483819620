import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "mobx-react";
import { RootApp } from "./client/root";

import stores from "./client/stores";

ReactDOM.render(
  <Provider {...stores}>
    <RootApp />
  </Provider>,

  document.getElementById("root") as HTMLElement
);
