import { observable, action } from "mobx";

export class AnswerStore {
  @observable public answerOne: Number | null;
  @observable public answerTwo: Number | null;
  @observable public answerThree: Number | null;

  constructor() {
    this.answerOne = null;
    this.answerTwo = null;
    this.answerThree = null;
	}
	
	@action
	public setAnswerOne = (answer: Number) => {
		this.answerOne = answer;
	}

	@action
	public setAnswerTwo = (answer: Number) => {
		this.answerTwo = answer;
	}

	@action
	public setAnswerThree = (answer: Number) => {
		this.answerThree = answer;
	}

}
