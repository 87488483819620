import * as React from "react";
import { observer, inject } from "mobx-react";
import { Header } from "../../components/header";
import { Privacy } from "../../components/privacy";
import { Problem } from "../../components/problem";
import { Popup } from "../../components/popup";
import "./master.css";

@inject("applicantState")
@observer
export class MasterPage extends React.Component<any, any> {
  public render() {
    const group = this.props.match.params.group;
    return (
      <React.Fragment>
        <div id="master">
          <div id="header">
            <Header />
          </div>
          <div id="section_one">
            <div className="tag_explain">
              <p>
                {" "}
                1. 먼저, 기본정보를 <br /> 입력해주세요.{" "}
              </p>
            </div>
            <div className="content">
              <Privacy />
            </div>
          </div>
          <div id="section_two">
            <div className="tag_explain">
              <p>
                {" "}
                2. 다음 문항의 답을 <br /> 선택/기입한 후, <br /> '제출'버튼을
                눌러주세요.{" "}
              </p>
            </div>
            <div className="content">
              <Problem />
            </div>
          </div>
          <div id="submit">
            <Popup group={group} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
