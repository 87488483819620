import * as React from "react";
import * as constants from "../common/constants";
import { withRouter, Route, BrowserRouter, Switch } from "react-router-dom";
import { MasterPage } from "./pages/master";
import { Page404 } from "./pages/404";

import ReactGA from "react-ga";

const GAPageView = withRouter(props => {
  ReactGA.pageview(props.location.pathname + props.location.search);
  return null;
});

// @inject("accountState")
export class RootApp extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    console.log(`Environment: ${constants.NODE_ENV}`);
    // ReactGA.initialize(constants.GA_ID);
  }

  public render() {
    return (
      <BrowserRouter>
        <React.Fragment>
          <GAPageView />
          <Switch>
            <Route path="/:group" component={MasterPage} />
            <Route path="/" component={MasterPage} />
            <Route component={Page404} />
          </Switch>
        </React.Fragment>
      </BrowserRouter>
    );
  }
}
