import * as React from "react";
import { observer, inject } from "mobx-react";
import "./privacy.css";

@inject("applicantState")
@observer
export class Privacy extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      name: "",
      department: "",
      phone: "",
      email: "",
      selectedOptionOne: "yes",
      selectedOptionTwo: "yes"
    };
  }

  public handleInputChange = (event: any) => {
    const {
      setName,
      setDepartment,
      setPhone,
      setEmail
    } = this.props.applicantState;
    if (event.target.id === "name") {
      this.setState({ name: event.target.value });
      setName(event.target.value);
    } else if (event.target.id === "department") {
      this.setState({ department: event.target.value });
      setDepartment(event.target.value);
    } else if (event.target.id === "phone") {
      this.setState({ phone: event.target.value });
      setPhone(event.target.value);
    } else if (event.target.id === "email") {
      this.setState({ email: event.target.value });
      setEmail(event.target.value);
    }
  };

  public handleOptionChange(option: Number, changeEvent: any) {
    const { setApply, setSubscribe } = this.props.applicantState;
    if (option === 1) {
      this.setState({
        selectedOptionOne: changeEvent.target.value
      });
      setApply(changeEvent.target.value);
    } else if (option === 2) {
      this.setState({
        selectedOptionTwo: changeEvent.target.value
      });
      setSubscribe(changeEvent.target.value);
    }
  }

  public render() {
    return (
      <div className="section_privacy">
        <form className="form_privacy">
          <label className="label_privacy">
            {" "}
            이름
            <input
              type="text"
              id="name"
              className="input_privacy"
              value={this.state.name}
              onChange={this.handleInputChange}
              autoComplete="off"
              required
            />{" "}
          </label>
          <br />
          <label className="label_privacy">
            {" "}
            소속
            <input
              type="text"
              id="department"
              className="input_privacy"
              value={this.state.department}
              onChange={this.handleInputChange}
              autoComplete="off"
              required
              placeholder="(ex.학교 학과 학년 / 혹은 회사, 연구소이름)"
            />{" "}
          </label>
          <br />
          <label className="label_privacy">
            {" "}
            핸드폰 번호
            <input
              type="number"
              id="phone"
              className="input_privacy"
              value={this.state.phone}
              onChange={this.handleInputChange}
              autoComplete="off"
              required
              placeholder="(ex.01011112222)"
            />{" "}
          </label>
          <br />
          <label className="label_privacy">
            {" "}
            이메일
            <input
              type="email"
              id="email"
              className="input_privacy"
              value={this.state.email}
              onChange={this.handleInputChange}
              autoComplete="off"
              required
              placeholder="(ex.comcom@gmail.com)"
            />{" "}
          </label>
          <br />
          <label className="label_privacy">
            채용 지원 여부
            <span className="check_select">
              <input
                type="radio"
                value="yes"
                checked={this.state.selectedOptionOne === "yes"}
                onChange={this.handleOptionChange.bind(this, 1)}
              />
              <label> Yes </label>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <input
                type="radio"
                value="no"
                checked={this.state.selectedOptionOne === "no"}
                onChange={this.handleOptionChange.bind(this, 1)}
              />
              <label> No </label>
              <br />
            </span>
          </label>
          <br />
          <label className="label_privacy">
            회사 소식 구독
            <span className="check_select">
              <input
                type="radio"
                value="yes"
                checked={this.state.selectedOptionTwo === "yes"}
                onChange={this.handleOptionChange.bind(this, 2)}
              />
              <label> Yes </label>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <input
                type="radio"
                value="no"
                checked={this.state.selectedOptionTwo === "no"}
                onChange={this.handleOptionChange.bind(this, 2)}
              />
              <label> No </label>
              <br />
            </span>
          </label>
        </form>
      </div>
    );
  }
}
