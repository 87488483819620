import * as React from 'react';

export class Page404 extends React.Component<any, any> {
  public render() {
    return (
      <div>
        <h1>Not found</h1>
        <p>Page you requested is not found. Please check your URL and try again.</p>
      </div>
    );
  }
}