import { ApplicantStore } from "./applicantStore";
import { AnswerStore } from "./answerStore";

const applicantStore = new ApplicantStore();
const answerStore = new AnswerStore();

export default {
  applicantState: applicantStore,
  answerState: answerStore
};
