import * as React from "react";
import { observer, inject } from "mobx-react";
import "./problem.css";

@inject("answerState")
@observer
class ProblemOne extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { answerOne: "" };

    this.handleOptionChange = this.handleOptionChange.bind(this);
  }
  public handleOptionChange(changeEvent: any) {
    const { setAnswerOne } = this.props.answerState;
    this.setState({
      answerOne: changeEvent.target.value
    });
    setAnswerOne(changeEvent.target.value);
  }

  render() {
    return (
      <div className="wrap_problem">
        {/* <p className="header_problem"> 문항 1. </p> */}
        <pre className="body_problem">
          <code>{`#include <stdio.h>

int main()
{
    int numArr[5] = { 11, 22, 33, 44, 55 };
    int *numPtrA;
    void *ptr;

    numPtrA = &numArr[2];
    ptr = numArr;

    printf("%d\\n", *(numPtrA + 2));
    printf("%d\\n", *((int *)ptr + 1));

    return 0;
}
          `}</code>
        </pre>

        <p className="header_answer"> 알맞은 답을 1개 골라주세요. </p>
        <div className="answer_choice">
          <input
            type="radio"
            value="1"
            checked={this.state.answerOne === "1"}
            onChange={this.handleOptionChange.bind(this)}
          />
          <label className="label_answer"> ① 55 ② 33 </label> <br />
          <input
            type="radio"
            value="2"
            checked={this.state.answerOne === "2"}
            onChange={this.handleOptionChange.bind(this)}
          />
          <label className="label_answer"> ① 55 ② 22 </label> <br />
          <input
            type="radio"
            value="3"
            checked={this.state.answerOne === "3"}
            onChange={this.handleOptionChange.bind(this)}
          />
          <label className="label_answer"> ① 22 ② 33 </label> <br />
          <input
            type="radio"
            value="4"
            checked={this.state.answerOne === "4"}
            onChange={this.handleOptionChange.bind(this)}
          />
          <label className="label_answer"> ① 33 ② 11 </label> <br />
        </div>
      </div>
    );
  }
}

@inject("answerState")
@observer
class ProblemTwo extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { answerOne: "" };

    this.handleOptionChange = this.handleOptionChange.bind(this);
  }
  public handleOptionChange(changeEvent: any) {
    const { setAnswerTwo } = this.props.answerState;
    this.setState({
      answerTwo: changeEvent.target.value
    });
    setAnswerTwo(changeEvent.target.value);
  }
  render() {
    return (
      <div className="wrap_problem">
        {/* <p className="header_problem"> 문항 2. </p> */}
        <pre className="body_problem">
          <code>{`#include <stdio.h>

main(){
  int a=1, b=8, c=4;
  printf("%d %d %d", ++a+c, b/a, b*c++);
}
          `}</code>
        </pre>

        <p className="header_answer"> 알맞은 답을 1개 골라주세요. </p>
        <div className="answer_choice">
          <input
            type="radio"
            value="1"
            checked={this.state.answerTwo === "1"}
            onChange={this.handleOptionChange.bind(this)}
          />
          <label className="label_answer"> 7 8 32 </label> <br />
          <input
            type="radio"
            value="2"
            checked={this.state.answerTwo === "2"}
            onChange={this.handleOptionChange.bind(this)}
          />
          <label className="label_answer"> 6 4 32 </label> <br />
          <input
            type="radio"
            value="3"
            checked={this.state.answerTwo === "3"}
            onChange={this.handleOptionChange.bind(this)}
          />
          <label className="label_answer"> 6 8 22 </label> <br />
          <input
            type="radio"
            value="4"
            checked={this.state.answerTwo === "4"}
            onChange={this.handleOptionChange.bind(this)}
          />
          <label className="label_answer"> 7 4 22 </label> <br />
        </div>
      </div>
    );
  }
}

@inject("answerState")
@observer
class ProblemThree extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { answerOne: "" };

    this.handleOptionChange = this.handleOptionChange.bind(this);
  }
  public handleOptionChange(changeEvent: any) {
    const { setAnswerThree } = this.props.answerState;
    this.setState({
      answerThree: changeEvent.target.value
    });
    setAnswerThree(changeEvent.target.value);
  }
  render() {
    return (
      <div className="wrap_problem">
        {/* <p className="header_problem"> 문항 3. </p> */}
        <p className="header_answer">
          {" "}
          TCP/IP 프로토콜에서 TCP가 해당하는 계층은?{" "}
        </p>
        <div className="answer_choice">
          <input
            type="radio"
            value="1"
            checked={this.state.answerThree === "1"}
            onChange={this.handleOptionChange.bind(this)}
          />
          <label className="label_answer"> 데이터 링크 계층 </label> <br />
          <input
            type="radio"
            value="2"
            checked={this.state.answerThree === "2"}
            onChange={this.handleOptionChange.bind(this)}
          />
          <label className="label_answer"> 네트워크 계층 </label> <br />
          <input
            type="radio"
            value="3"
            checked={this.state.answerThree === "3"}
            onChange={this.handleOptionChange.bind(this)}
          />
          <label className="label_answer"> 트랜스포트 계층 </label> <br />
          <input
            type="radio"
            value="4"
            checked={this.state.answerThree === "4"}
            onChange={this.handleOptionChange.bind(this)}
          />
          <label className="label_answer"> 셰션 계층 </label> <br />
        </div>
      </div>

      /* <div className="wrap_problem">
      <p className="header_problem"> 문항 2. </p>
      <input type="text" className="body_problem" />
      <p className="header_answer"> 아래에 답을 작성해주세요. </p>
      <input
        type="text"
        className="answer_short"
        autoComplete="off"
        required
      />
    </div> */
    );
  }
}
export class Problem extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }
  randomize(problems: any) {
    return problems[Math.floor(Math.random() * problems.length)];
  }
  public render() {
    const problems = [<ProblemOne />, <ProblemTwo />, <ProblemThree />];
    return <div className="section_problem">{this.randomize(problems)}</div>;
  }
}
