import * as React from "react";
import comcomLogo from "../../../assets/img/comcom-logo.png";
import "./header.css";

export class Header extends React.Component<any, any> {
  public render() {
    return (
      <div className="section_header">
        <a href="https://ainetwork.ai/" className="logo_comcom">
          <img src={comcomLogo} className="img_logo" alt="AI NETWORK" />
        </a>
      </div>
    );
  }
}
