import { observable, action } from "mobx";

export class ApplicantStore {
  @observable public name: string | null;
  @observable public department: string | null;
  @observable public phone: Number | null;
	@observable public email: string | null;
	@observable public apply: string;
	@observable public subscribe: string;

  constructor() {
    this.name = null;
    this.department = null;
    this.phone = null;
		this.email = null;
		this.apply = "yes";
		this.subscribe = "yes";
	}
	
	@action
	public setName = (newName: string) => {
		this.name = newName;
	}

	@action
	public setDepartment = (newDepartment: string) => {
		this.department = newDepartment;
	}

	@action setPhone = (newPhone: Number) => {
		this.phone = newPhone;
	}

  @action
  public setEmail = (newEmail: string) => {
    this.email = newEmail;
	};
	
	@action
	public setApply = (newApply: string) => {
		this.apply = newApply;
	}

	@action
	public setSubscribe = (newSubscribe: string) => {
		this.subscribe = newSubscribe;
	}

}
