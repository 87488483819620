import * as Firebase from "firebase";
import * as constants from "./constants";

const prodConfig = {
  apiKey: "AIzaSyD7eUkEd-kf6Sb-PiHyPeBD2HxcAeS_ZpU",
  authDomain: "jobfair-2019.firebaseapp.com",
  databaseURL: "https://jobfair-2019.firebaseio.com",
  projectId: "jobfair-2019",
  storageBucket: "jobfair-2019.appspot.com",
  messagingSenderId: "324204763998",
  appId: "1:324204763998:web:18adcbcc93d0762e3c8dcf",
  measurementId: "G-968VQPY7M3"
};

const devConfig = {
  apiKey: "AIzaSyBDEiwHp0iWlKPFppFeuAvedCYAe2ypn4s",
  authDomain: "job-fair-dev.firebaseapp.com",
  databaseURL: "https://job-fair-dev.firebaseio.com",
  projectId: "job-fair-dev",
  storageBucket: "job-fair-dev.appspot.com",
  messagingSenderId: "993071424917",
  appId: "1:993071424917:web:18bd9b437cf1d5a507429c",
  measurementId: "G-5R1EHMB7L4"
};

const config = constants.NODE_ENV === "production" ? prodConfig : devConfig;

const app = Firebase.initializeApp(config);
export const firebase = Firebase;
export const firebaseApp = app;
export const database = app.database();

export default class firebaseWrapper {
  public static async databaseUpdate(updates: any) {
    database.ref().update(updates);
  }

  public static async databasePush(path: string, data: Object) {
    database
      .ref()
      .child(path)
      .push(data);
  }

  public static databaseGet = async (
    path: string,
    key: string,
    setter: Function
  ) => {
    return await database
      .ref()
      .child(path)
      .once("value", async (snapshot) => {
        await setter(snapshot.val()[key]);
      });
  };

  public static off = (address: string) => {
    return database.ref(address).off();
  };
}
