import * as React from "react";
import Modal from "react-modal";
import { observer, inject } from "mobx-react";
import firebaseWrapper from "../../../common/firebase";
import "./popup.css";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};

@inject("applicantState", "answerState")
@observer
export class Popup extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      modalIsOpen: false,
      warningModalIsOpen: false
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openWarningModal = this.openWarningModal.bind(this);
    this.closeWarningModal = this.closeWarningModal.bind(this);
  }

  public uploadInfo = () => {
    const {
      name,
      department,
      phone,
      email,
      apply,
      subscribe
    } = this.props.applicantState;
    const { answerOne, answerTwo, answerThree } = this.props.answerState;
    firebaseWrapper
      .databasePush(`/applicants/${this.props.group}`, { 
        name,
        department,
        phone,
        email,
        agree: { apply, subscribe },
        answer: { answerOne, answerTwo, answerThree }
      })
      .then(() => {
        this.openModal();
      });
  };

  public handleClick = (mode: boolean, event: any) => {
    if(!mode) {
      this.openWarningModal();
      return;
    }
    event.preventDefault();
    this.uploadInfo();
  };

  public openModal() {
    this.setState({ modalIsOpen: true });
  }

  public closeModal() {
    this.setState({ modalIsOpen: false });
    window.location.reload();
  }

  public openWarningModal() {
    this.setState({ warningModalIsOpen: true });  
  }

  public closeWarningModal() {
    this.setState({ warningModalIsOpen: false });
  }
  
  public checkCondition = () => {
    const { name, department, phone, email } = this.props.applicantState;
    // const { answerOne, answerTwo, answerThree } = this.props.answerState;
    if (!name || !department || !phone || !email) {
      return true;
    }
    // if (!answerOne || !answerTwo || !answerThree) {
    //   return true;
    // }
    return false;
  };

  render() {
    return (
      <div id="popup">
        <button
          className="submit"
          onClick={this.handleClick.bind(this, navigator.onLine)}
          disabled={this.checkCondition()}
        >
          {" "}
          제출{" "}
        </button>
        <Modal
          isOpen={this.state.modalIsOpen}
          style={customStyles}
          ariaHideApp={false}
        >
          <div className="modal">
            <p>
              {" "}
              답변이 제출되었습니다. <br />
              Come to ComCom!{" "}
            </p>
            <button className="close" onClick={this.closeModal}>
              {" "}
              완료{" "}
            </button>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.warningModalIsOpen}
          style={customStyles}
          ariaHideApp={false}
        >
          <div className="modal">
            <p>
              {" "}
              인터넷 연결을 확인해주세요. <br />
              Come to ComCom!{" "}
            </p>
            <button className="close" onClick={this.closeWarningModal}>
              {" "}
              재시도{" "}
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}
